import { methods } from "vt-notifications/src/notifications";
import router from "@/router";
import i18n from "../i18n";

const requestMethods = ["put", "post", "patch", "delete"];

const notificationSuccessInterceptor = (response) => {
  const hasNotificationProperty = Object.prototype.hasOwnProperty.call(
    response.data,
    "notification"
  );

  // if response has no notification-property or if it's set to true
  if (!hasNotificationProperty || response.data.notification === true) {
    const notification = Object.assign(
      {
        group: "bottom",
        type: "success",
        title: i18n.t("notifications.success.general"),
      },
      response.data.notification
    );

    if (requestMethods.includes(response.config.method) && !hasNotificationProperty) {
      switch (response.config.method) {
        case "delete":
          notification.message = i18n.t("notifications.success.delete.text");
          break;
        case "patch":
          notification.message = i18n.t("notifications.success.patch.text");
          break;
        default:
          notification.message = i18n.t("notifications.success.post.text");
      }

      methods.notify({
        group: notification.group,
        type: notification.type,
        title: notification.title,
        message: notification.message,
      });
    }
  }

  // if response has notification-property which is set to true
  if (hasNotificationProperty && response.data.notification === true) {
    const notification = Object.assign(
      {
        group: "bottom",
        type: "success",
        title: i18n.t("notifications.success.general"),
      },
      response.data.notification
    );

    methods.notify({
      group: notification.group,
      type: notification.type,
      title: notification.title,
      message: notification.message,
    });
  }

  return response;
};

const notificationFailureInterceptor = (error) => {
  if (error.response.status === 500) {
    methods.notify({
      group: "bottom",
      type: "error",
      title: i18n.t("notifications.error.server.title"),
      message: i18n.t("notifications.error.server.text"),
    });
  }

  if (error.response.status === 422) {
    methods.notify({
      group: "bottom",
      type: "error",
      title: i18n.t("notifications.error.validation.title"),
      message: i18n.t("notifications.error.validation.text"),
    });
  }

  if (error.response.status === 401) {
    methods.notify({
      group: "bottom",
      type: "error",
      title: i18n.t("notifications.error.unauthorized.title"),
      message: i18n.t("notifications.error.unauthorized.text"),
    });
    router.push({ name: "login" });
    return true;
  }

  if (error.response.status === 403) {
    methods.notify({
      group: "bottom",
      type: "error",
      title: i18n.t("notifications.error.forbidden.title"),
      message: i18n.t("notifications.error.forbidden.text"),
    });
  }

  const hasNotificationProperty = Object.prototype.hasOwnProperty.call(
    error.response.data,
    "notification"
  );

  if (hasNotificationProperty && error.response.data.notification !== false) {
    let notification = Object.assign(
      {
        group: "bottom",
        type: "error",
        title: i18n.t("notifications.error.general"),
      },
      error.response.data.notification
    );

    console.log(notification);

    methods.notify({
      group: notification.group,
      type: notification.type,
      title: i18n.t(notification.title),
      message: i18n.t(notification.message),
    });
  }

  return Promise.reject(error);
};

export { notificationSuccessInterceptor, notificationFailureInterceptor };
