import store from "../store";

const redirectIfAuthenticated = (to, from, next) => {
    const user = store.getters["auth/user"];
    const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
    const loginQuery = {path: "/login", query: {redirect: to.fullPath}};

    if (reqAuth && !user) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (!store.getters["auth/user"]) {
                next(loginQuery);
            } else {
                next();
            }
        });
    } else {
        next();
    }
};

export {redirectIfAuthenticated};
