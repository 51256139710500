<template>
  <transition name="modal-fade">
    <div class="fixed z-30 inset-0 overflow-y-auto sm:overflow-y-hidden">
      <div
          :class="{
            'flex items-start justify-center min-h-screen text-center sm:block sm:p-0 sm:overflow-y-auto z-20 sm:h-screen': true,
            'pt-4 px-4 pb-20': !full,
          }">
        <div
            class="fixed inset-0 transition-opacity"
            style="z-index: -10"
            @click.prevent="close"
            aria-hidden="true">
          <div class="fixed inset-0 bg-gray-lighter opacity-80"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
            :class="{
          'w-full z-10 relative inline-block bg-darkbg text-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full': true,
          'align-bottom sm:align-middle sm:my-8': !full,
          'px-4 pt-5 pb-4 sm:p-6': !noContentPadding,
          'sm:max-w-lg': !large && !full,
          'sm:max-w-3xl': large && !full,
          'align-top h-full': full,
           }"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline">
          <div class="sm:block fixed z-30 top-0 right-0 pt-4 pr-4">
            <button
                v-if="closable && !hideCloseIcon"
                @click.prevent="close"
                type="button"
                class="
                rounded-md
                text-gray-400
                hover:text-gray-500
                focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-primary
              ">
              <span class="sr-only">Close</span>
              <!-- Heroicon name: outline/x -->
              <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>

          <slot name="body" :close="close"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props: {
    hideCloseIcon: {
      default: false,
      type: Boolean,
    },
    noContentPadding: {
      default: false,
      type: Boolean,
    },
    closable: {
      default: true,
      type: Boolean,
    },
    large: {
      default: false,
      type: Boolean,
    },
    full: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    };

    document.addEventListener("keydown", escapeHandler);
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
