var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"fixed z-30 inset-0 overflow-y-auto sm:overflow-y-hidden"},[_c('div',{class:{
          'flex items-start justify-center min-h-screen text-center sm:block sm:p-0 sm:overflow-y-auto z-20 sm:h-screen': true,
          'pt-4 px-4 pb-20': !_vm.full,
        }},[_c('div',{staticClass:"fixed inset-0 transition-opacity",staticStyle:{"z-index":"-10"},attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"fixed inset-0 bg-gray-lighter opacity-80"})]),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{class:{
        'w-full z-10 relative inline-block bg-darkbg text-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full': true,
        'align-bottom sm:align-middle sm:my-8': !_vm.full,
        'px-4 pt-5 pb-4 sm:p-6': !_vm.noContentPadding,
        'sm:max-w-lg': !_vm.large && !_vm.full,
        'sm:max-w-3xl': _vm.large && !_vm.full,
        'align-top h-full': _vm.full,
         },attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_c('div',{staticClass:"sm:block fixed z-30 top-0 right-0 pt-4 pr-4"},[(_vm.closable && !_vm.hideCloseIcon)?_c('button',{staticClass:"rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close")]),_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])]):_vm._e()]),_vm._t("body",null,{"close":_vm.close})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }