import { get, isNil } from "lodash";

export function isNullOrUndefined(str) {
  return isNil(str);
}

export function isFilledString(str) {
  return (
    !isNil(str) && // Not undefined or null
    typeof str === "string" && // Is string
    str.trim().length > 0 // Not empty (even trimmed)
  );
}

export function isArray(arr) {
  return (
    !isNil(arr) && // Not undefined or null
    Array.isArray(arr) // Is array
  );
}

export function isFilledArray(arr) {
  return (
    isArray(arr) && arr.length > 0 // Has at least on element
  );
}

export function isFilledObject(obj) {
  return (
    !isNil(obj) && // Not undefined or null
    typeof obj === "object" && // Is type of object
    !Array.isArray(obj) && // Is not an array
    Object.keys(obj).length > 0 // Has properties
  );
}

export function isBooleanTrue(bool, isWeakCheck = true) {
  if (typeof bool === "boolean") {
    return bool;
  }
  if (isWeakCheck) {
    if (isNullOrUndefined(bool)) {
      return false;
    }

    if (typeof bool === "string") {
      return bool === "true" || bool === "TRUE";
    }

    throw new Error("Weak boolean check failed");
  } else {
    throw new Error("Boolean check failed");
  }
}

export function isPromise(obj) {
  return (
    !isNil(obj) && // Not undefined or null
    typeof obj === "object" && // Is type of object
    typeof obj.then === "function" // Has then function
  );
}

export function isNumber(num) {
  return (
    !Number.isNaN(num) && typeof num === "number" // Is type of number
  );
}

export function isPositiveNumber(num) {
  const parsedNumber = parseInt(num, 10);

  return isNumber(parsedNumber) && parsedNumber >= 1;
}

export function hasObjectProperty(obj, property) {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

export function transformData(data = null, transformationRules = null) {
  if (isFilledArray(transformationRules)) {
    return data;
  }

  const newData = data;

  Object.entries(transformationRules).forEach((transformationRule) => {
    if (typeof transformationRule[1] === "string") {
      if (Object.prototype.hasOwnProperty.call(newData, transformationRule[0])) {
        newData[transformationRule[0]] = newData[transformationRule[1]];
      }
    }

    if (typeof transformationRule[1] === "function") {
      newData[transformationRule[0]] = transformationRule[1](newData);
    }
  });

  return newData;
}

export function stringIncludes(haystack, needle) {
  if (isNumber(haystack)) {
    haystack = haystack.toString();
  }

  return (
    isFilledString(haystack) &&
    haystack !== "" &&
    haystack.replace(/ /g, "").toLowerCase().includes(needle.replace(/ /g, "").toLowerCase())
  );
}

export function getValueByKeyInDotNotation(data, keyInDotNotation) {
  return get(data, keyInDotNotation, null);
}

export function filterItemsBySearchText(items, keys, search) {
  return items.filter((r) =>
    keys.some((key) => stringIncludes(getValueByKeyInDotNotation(r, key), search))
  );
}

export function roundDownToBase(numericValue, base) {
  return Math.trunc(numericValue / base) * base;
}

export function roundWithFraction(numericValue, fraction = 2) {
  return ((numericValue * 100) / 100).toFixed(fraction);
}

export function cloneWithoutReference(variable) {
  return JSON.parse(JSON.stringify(variable));
}

export function createUniqueKey() {
  return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toLowerCase();
}

export function isFunction(obj) {
  return typeof obj === "function";
}

export function isError(obj) {
  return obj instanceof Error;
}

export function isStringEvenEmpty(str) {
  return (
    !isNil(str) && // Not undefined or null
    typeof str === "string" // Is string
  );
}

export function isStringInArray(needle, haystack, isCaseInsensitive = false) {
  if (!isFilledArray(haystack)) {
    return false;
  }

  if (isCaseInsensitive === true) {
    needle = needle.toLowerCase();
    haystack = haystack.map((x) => x.toLowerCase());
  }

  return haystack.includes(needle);
}

export function getApiEndpointPrefix(appConfig) {
  const { scheme, host, port, postfix } = appConfig.apiEndpoint;
  const finalHost = host === "" ? window.location.hostname : host;
  const finalPort = port === "" ? "" : `:${port}`;

  return `${scheme}://${finalHost}${finalPort}${postfix}`;
}
