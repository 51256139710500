import apiClient from "../services/ApiClient";

export const namespaced = true;

export const state = {
    authenticated: false,
    user: null,
    error: false,
    loading: false,
    event: null,
};

export const mutations = {
    set_authenticated(state, authenticated) {
        state.authenticated = authenticated;
    },
    set_error(state, error) {
        state.error = error;
    },
    set_user(state, user) {
        state.user = user;
    },
    set_loading(state, loading) {
        state.loading = loading;
    },
    set_event(state, event) {
        state.event = event;
    },
};

export const actions = {
    async login({commit}, credentials) {
        commit("set_loading", true);
        commit("set_error", false);
        try {
            const {data} = await apiClient.post("/login", credentials);

            if (data.success) {
                commit("set_authenticated", true);
                commit("set_user", data.user);
            } else {
                commit("set_authenticated", false);
                commit("set_user", null);
            }

            commit("set_loading", false);
        } catch (e) {
            commit("set_error", true);
            commit("set_loading", false);
        }
    },
    async logout({commit}) {
        commit("set_loading", true);
        commit("set_error", false);
        try {
            await apiClient.post("logout");
            commit("set_user", null);
            commit("set_authenticated", false);
            commit("set_loading", false);
        } catch (e) {
            commit("set_error", true);
            commit("set_loading", false);
        }
    },

    async setEvent({commit}, uuid) {
        commit("set_loading", true);
        commit("set_error", false);
        try {
            const { data } = await apiClient.post("user/currentEvent", {
                uuid: uuid,
            });
            commit("set_event", data.uuid);
            commit("set_loading", false);
        } catch (e) {
            commit("set_error", true);
            commit("set_loading", false);
        }
    },

    async getAuthUser({commit}) {
        commit("set_loading", true);
        commit("set_error", false);
        try {
            const {data} = await apiClient.get("user");

            if (data){
                commit("set_authenticated", true);
                commit("set_user", data);
                commit("set_user", data);
            }else{
                commit("set_authenticated", false);
                commit("set_user", null);
            }

            commit("set_loading", false);
            return data;
        } catch (error) {
            commit("set_error", true);
            commit("set_loading", false);
        }
    },

    async updateAuthUser({commit}, payload) {
        commit("set_loading", true);
        commit("set_error", false);
        try {
            const {data} = await apiClient.patch("user", payload);
            commit("set_user", data.data);
            commit("set_loading", false);
            return data.data;
        } catch (error) {
            commit("set_error", true);
            commit("set_loading", false);
        }
    },
};

export const getters = {
    authenticated: (state) => state.authenticated,
    user: (state) => state.user,
    loggedIn: (state) => !!state.user,
    loading: (state) => state.loading,
    error: (state) => state.error,
    event: (state) => state.event,
};
