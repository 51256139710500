export const namespaced = true;

export const state = {
  first_name: null,
  last_name: null,
  street: null,
  postal: null,
  city: null,
  gender: null,
  phone: null,
  phone_country: null,
  birthdate: null,
  email: null,
  passport: null,
  remembered: false,
  locale: null,
};

export const mutations = {
  set_email(state, email) {
    state.email = email;
  },
  set_first_name(state, firstName) {
    state.first_name = firstName;
  },
  set_last_name(state, lastName) {
    state.last_name = lastName;
  },
  set_street(state, street) {
    state.street = street;
  },
  set_phone(state, phone) {
    state.phone = phone;
  },
  set_phone_country(state, phone_country) {
    state.phone_country = phone_country;
  },
  set_postal(state, postal) {
    state.postal = postal;
  },
  set_city(state, city) {
    state.city = city;
  },
  set_gender(state, gender) {
    state.gender = gender;
  },
  set_birthdate(state, birthdate) {
    state.birthdate = birthdate;
  },
  set_remembered(state, remembered) {
    state.remembered = remembered;
  },
  set_locale(state, locale) {
    state.locale = locale;
  },
  set_passport(state, passport) {
    state.passport = passport;
  },
};

export const actions = {
  rememberMe({ commit }, user) {
    console.log(user);
    commit("set_email", user.email);
    commit("set_first_name", user.first_name);
    commit("set_last_name", user.last_name);
    commit("set_street", user.street);
    commit("set_postal", user.postal);
    commit("set_city", user.city);
    commit("set_gender", user.gender);
    commit("set_phone", user.phone);
    commit("set_phone_country", user.phone_country);
    commit("set_birthdate", user.birthdate);
    commit("set_passport", user.passport);
    commit("set_remembered", true);
  },

  setLocale({ commit }, locale) {
    commit("set_locale", locale);
  },

  forgotMe({ commit }) {
    commit("set_email", null);
    commit("set_first_name", null);
    commit("set_last_name", null);
    commit("set_street", null);
    commit("set_postal", null);
    commit("set_city", null);
    commit("set_gender", null);
    commit("set_phone", null);
    commit("set_phone_country", null);
    commit("set_birthdate", null);
    commit("set_passport", null);
    commit("set_remembered", false);
  },
};

export const getters = {
  passport: (state) => state.passport,
  email: (state) => state.email,
  first_name: (state) => state.first_name,
  last_name: (state) => state.last_name,
  street: (state) => state.street,
  postal: (state) => state.postal,
  city: (state) => state.city,
  gender: (state) => state.gender,
  phone: (state) => state.phone,
  phone_country: (state) => state.phone_country,
  birthdate: (state) => new Date(state.birthdate),
  remembered: (state) => state.remembered,
  locale: (state) => state.locale,
};
