import Vue from "vue";
import VueI18n from "vue-i18n";

import messages from "@locales";
import { getBrowserLocales } from "./helpers/browserLocale";

Vue.use(VueI18n);

const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
  },
  'de-DE': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
  }
}

export default new VueI18n({
  locale: getBrowserLocales({ languageCodeOnly: true })[0],
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages,
  dateTimeFormats
});
