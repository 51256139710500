import axios from "axios";
import {
  notificationFailureInterceptor,
  notificationSuccessInterceptor,
} from "@/interceptors/NotificationInterceptor";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

apiClient.interceptors.response.use(notificationSuccessInterceptor, notificationFailureInterceptor);

export default apiClient;
