import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {isFilledString} from './helpers/generalHelper';
import App from "./App.vue";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
// import vueCrypt from "vue-crypt";
import VueBarcodeScanner from "vue-barcode-scanner";
import modal from "@components/global/Modal";
// import VueSignaturePad from "vue-signature-pad";
import LaravelEncrypt from "./services/LaravelEncrypt";
import VueApexCharts from "vue-apexcharts";
import Notifications from "vt-notifications";
import FlagIcon from 'vue-flag-icon'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";

import "./fonts.css";
import "./index.css";
import apiClient from "./services/ApiClient";
import {formatInTimeZone} from "date-fns-tz";

Vue.prototype.$isDevMode = process.env.NODE_ENV === "development";


if (!Vue.prototype.$isDevMode) {
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
    Vue.config.productionTip = false;
}

// ENABLE AUTHENTICATION WITH AXIOS
axios.defaults.withCredentials = true;

// if (!Vue.prototype.$isDevMode) {
//   Bugsnag.start({
//     apiKey: "",
//     plugins: [new BugsnagPluginVue()],
//   });
//   const bugsnagVue = Bugsnag.getPlugin("vue");
//   bugsnagVue.installVueErrorHandler(Vue);
// }

Vue.prototype.$http = axios;

Vue.prototype.$axios = apiClient;

Vue.prototype.$can = function (permission, options) {
    const user = this.$store.getters["auth/user"];
    return (user &&
        user.perms &&
        user.perms.includes(permission) &&
        !(options && options['excludeAdmin'] && user.perms.includes("admin")));
};
Vue.prototype.$sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};

Vue.mixin({
    methods: {
        isObjectEmpty: function (object) {
            return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
        },
    },
})


Vue.prototype.$handleError = function (err) {
    const errors = err.response.data.errors;
    let messageDetected = false;
    for (var key in errors) {
        const error = errors[key];
        if (Array.isArray(error) && error[0].match(/^error\./)) {
            const message = this.$tc(error[0]);
            // console.log(`Errorhandler: ${message}`)
            this.$root.$notify.show(this.$tc("error.general.head"), message);
            messageDetected = true;
        }
    }

    return messageDetected;
};

Vue.prototype.$translateErrors = function (errors) {

    // Is deep error response
    if (errors.response) {
        errors = errors.response.data.errors;
    }

    let errorMessages = [];

    for (const key in errors) {
        let error = errors[key];

        if (Array.isArray(error)) {
            error = error[0];
        }

        if (error.match(/error\./)) {
            errorMessages.push(this.$tc(error));
        } else {
            errorMessages.push(error);
        }
    }

    return errorMessages;
};

Vue.filter("currency", function (value, minimumFractionDigits) {
    typeof minimumFractionDigits != "number" && (minimumFractionDigits = 2);
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits,
    });
    return formatter.format(value);
});

Vue.filter("decimal", function (value, minimumFractionDigits, length) {
    typeof minimumFractionDigits != "number" && (minimumFractionDigits = 3);
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits,
    });

    const formattedValue = formatter.format(value);

    return length ? formattedValue.padStart(length, " ") : formattedValue;
});

Vue.filter("numberFormat", function (value, options) {
    // if (typeof value !== 'number') {
    options && options.parse && (value = parseFloat(value));

    var formatter = new Intl.NumberFormat("de-DE", options);

    return formatter.format(value);
});

Vue.filter("formatDateToDay", function (val) {
    if (!isFilledString(val)) {
        return "";
    }

    return format(parseISO(val), "iiii", {locale: de});
});

Vue.filter("formatToMintues", function (val) {
    if (!isFilledString(val)) {
        return "";
    }

    return formatInTimeZone(parseISO(val), 'UTC', 'HH:mm');
});


Vue.filter("formatTimeToMintues", function (time) {
    if (!isFilledString(time)) {
        return "";
    }

    let isoTime = '1970-01-01T' + time + 'Z';

    return formatInTimeZone(parseISO(isoTime), 'UTC', 'HH:mm');
});

Vue.filter("formatDate", function (val, formatString = "dd.MM.yyyy") {
    if (!isFilledString(val)) {
        return "";
    }

    return format(parseISO(val), formatString);
});

// NC for NoConversion
Vue.filter("formatDateNC", function (val, formatString = "dd.MM.yyyy") {
    if (!isFilledString(val)) {
        return "";
    }

    return formatInTimeZone(parseISO(val), 'UTC', formatString);
});

Vue.filter("formatDateWithWeekday", function (val) {
    if (!isFilledString(val)) {
        return "";
    }

    return format(parseISO(val), "iiii", {locale: de}) + ", " + format(parseISO(val), "dd.MM.yyyy");
});

Vue.filter("DateIso8601", function (val) {
    if (!isFilledString(val)) {
        return "";
    }
    return format(val, "yyyy-MM-dd");
});

Vue.filter("price", function (amount, currency, locale = 'de') {
    // https://docs.stripe.com/currencies#zero-decimal
    const zeroDigitsList = [
        'BIF',
        'CLP',
        'DJF',
        'GNF',
        'JPY',
        'KMF',
        'KRW',
        'MGA',
        'PYG',
        'RWF',
        'UGX',
        'VND',
        'VUV',
        'XAF',
        'XOF',
        'XPF',
    ];

    let value = amount;

    // fix zero-digits-currency bug
    if (!zeroDigitsList.includes(currency.toUpperCase())) value = amount / 100;

    return value.toLocaleString(locale, {
        style: "currency",
        currency: currency
    });
});

Vue.prototype.$encrypt = new LaravelEncrypt(process.env.VUE_APP_KEY);

Vue.use(FlagIcon);
Vue.use(Notifications);
// Vue.use(VueSignaturePad);
Vue.use(Vuex);
// Vue.use(vueCrypt);
Vue.use(VueBarcodeScanner, {
    requiredAttr: false,
    sensitivity: 50,
    callbackAfterTimeout: true,
    controlSequenceKeys: ["y", "z"],
});
Vue.use(VueApexCharts);

// Vue.component("apexchart", VueApexCharts);
Vue.component("Modal", modal);


// Slugify (partly from here: https://gist.github.com/codeguy/6684588)
String.prototype.slugify = function (separator = "-") {
    return this
        .toString()
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/\./g, '')
        .replace(/,/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .normalize('NFD')                   // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, separator);
};

Vue.prototype.$debug = function (message) {
    if (Vue.prototype.$isDevMode) {
        console.log(message);
    }
}

new Vue({
    router,
    store,
    i18n,
    axios,
    render: (h) => {
        store.dispatch("app/getVersion");
        return h(App);
    },
}).$mount("#app");
