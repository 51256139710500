import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import * as auth from "./auth";
import * as appointment from "./appointment";
import * as app from "./app";

const storage = new VuexPersistence({
  storage: window.localStorage,
  key: "hyrox_pft",
  reducer: (state) => ({
    appointment: {
      first_name: state.appointment.first_name,
      last_name: state.appointment.last_name,
      street: state.appointment.street,
      postal: state.appointment.postal,
      city: state.appointment.city,
      gender: state.appointment.gender,
      phone: state.appointment.phone,
      phone_country: state.appointment.phone_country,
      birthdate: state.appointment.birthdate,
      email: state.appointment.email,
      passport: state.appointment.passport,
      remembered: state.appointment.remembered,
      locale: state.appointment.locale,
    },
    auth: {
      location: state.auth.location,
    },
    app: {
      updated: state.app.updated,
    },
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    appointment,
    app,
  },
  plugins: [storage.plugin],
});
