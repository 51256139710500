import {version} from "@package";
import apiClient from "../services/ApiClient";
import * as semver from "semver";

export const namespaced = true;

export const state = {
    version: version,
    serverVersion: null,
    upToDate: true,         // prevent short appearance of the versions modal
    error: null,
    loading: false,
    lastVersionCheck: null,
};

export const mutations = {
    set_server_version(state, version) {
        state.serverVersion = version;
    },
    set_loading(state, loading) {
        state.loading = loading;
    },
    set_error(state, error) {
        state.error = error;
    },
    set_up_to_date(state, updated) {
        state.upToDate = updated;
    },
    set_last_version_check(state, timestamp) {
        state.lastVersionCheck = timestamp;
    },
};

export const actions = {
    async getVersion({commit, state}) {
        const timestamp = new Date().getTime();
        commit("set_loading", true);
        try {
            // do not check the version more than once a minute
            if (timestamp - state.lastVersionCheck > 60000) {
                const {data} = await apiClient.get("app/version", {params: {timestamp}});
                commit("set_server_version", data.appVersion);
                commit("set_last_version_check", timestamp);
                commit("set_up_to_date", !['major', 'premajor', 'minor'].includes(semver.diff(state.version, data.appVersion)));
            }
            commit("set_loading", false);
        } catch (e) {
            commit("set_error", e);
        }
    },
};

export const getters = {
    upToDate: (state) => state.upToDate,
    currentVersion: (state) => state.version,
    newVersion: (state) => state.serverVersion,
};
