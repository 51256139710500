import Vue from "vue";
import VueRouter from "vue-router";
import {redirectIfAuthenticated} from "@/interceptors/RedirectIfAuthenticated";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        redirect: "/login",
    },
    {
        path: "/city/:urlkey",
        name: "BookingCity",
        component: () =>
            import(
                "@views/public/register.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/location/:urlkey",
        name: "BookingLocation",
        component: () =>
            import(
                "@views/public/register.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/event/:urlkey",
        name: "BookingEvent",
        component: () => import("@views/public/register.vue"),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/country/:urlkey",
        name: "BookingCountry",
        component: () =>
            import(
                "@views/public/register.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/global",
        name: "BookingGlobal",
        component: () =>
            import(
                "@views/public/register.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/globalranking",
        name: "RankingGlobal",
        redirect: "/ranking/global",
    },
    {
        path: "/ranking/:filter/:urlkey?",
        name: "Ranking",
        component: () =>
            import(
                "@views/public/ranking.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/cancel/:urlkey/:cancel_uuid",
        name: "Cancel",
        component: () =>
            import(
                "@views/public/cancel.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },

    {
        path: "/info/:urlkey/",
        name: "AppointmentInfo",
        component: () =>
            import(
                "@views/public/info.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import(
                "@views/auth/login.vue"
                ),
        meta: {
            guest: true,
            requiresAuth: false,
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        redirect: "/checkin/registered",
    },
    {
        path: "/checkin",
        redirect: "/checkin/registered",
    },
    {
        path: "/checkin/registered",
        name: "Checkin",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/checkin.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/results",
        name: "Results",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/results.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/archive",
        name: "Archive",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/administration/archive.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reporting",
        name: "Reporting",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/administration/reporting.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/countries",
        name: "Countries",
        component: () => import(/* webpackChunkName: "checkin" */ "../views/dashboard/administration/countries"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/cities",
        name: "Cities",
        component: () => import(/* webpackChunkName: "checkin" */ "../views/dashboard/administration/cities"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/locations",
        name: "Locations",
        component: () => import(/* webpackChunkName: "checkin" */ "../views/dashboard/administration/locations"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/events",
        name: "Events",
        component: () => import(/* webpackChunkName: "checkin" */ "../views/dashboard/administration/events"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/users",
        name: "Users",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/administration/users.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/password",
        name: "Password",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/administration/password.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "checkin" */ "@views/dashboard/administration/settings.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/:pathMatch(.*)',
        name: 'error',
        component: () => import("@views/public/error.vue")
        ,
    }
];


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(redirectIfAuthenticated);

export default router;
