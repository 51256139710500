<template>
  <div>

    <!-- public route -->
    <div class="app" v-if="!isLoggedIn || $route.meta.guest || $route.meta.requiresAuth === false">
      <router-view/>
    </div>

    <!-- restricted route -->
    <div class="app" v-else>
      <div class="h-screen md:flex overflow-x-hidden bg-darkgray justify-center">
        <sidebar/>
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <!--          <UpdateInfoBox></UpdateInfoBox>-->
          <div class="py-6">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pb-12">
              <router-view/>
            </div>
          </div>
        </main>
        <notification-wrapper/>
      </div>
    </div>
    <modal v-if="!upToDate" :closable="false">
      <template #body>
        <div class="sm:flex sm:items-start">
          <div
              class="
              mx-auto
              flex-shrink-0 flex
              items-center
              justify-center
              h-12
              w-12
              rounded-full
              bg-green-700
              sm:mx-0 sm:h-10 sm:w-10
            ">
            <svg
                class="h-6 w-6 text-green-100"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div class="flex items-center flex-col sm:flex-row">
              <h3 class="text-lg inline leading-6 font-medium" id="modal-headline">
                {{ $tc("app.differentVersion.title") }}
              </h3>
              <div class="flex space-x-2 sm:ml-3">
                <span class="
                    text-xs
                    bg-gray-400
                    rounded
                    px-2 py-1">
                  {{ $store.getters["app/currentVersion"] }}
                </span>
                <span
                    class="
                    text-xs
                    bg-green-700
                    rounded
                    text-green-100
                    px-2
                    py-1
                  ">{{ $store.getters["app/newVersion"] }}</span>
              </div>
            </div>
            <div class="mt-2">
              <p class="text-sm text-gray-500" v-html="$tc('app.differentVersion.description')"></p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <XButton
              design="primary"
              :label="$tc('app.differentVersion.reload')"
              @click.prevent="reload"/>
        </div>
      </template>
    </modal>

    <Notify ref="notify" />
  </div>
</template>

<style lang="scss">
@import "../src/styles/global.scss";
</style>

<script>

export default {
  components: {
    XButton: () => import("@components/form/XButton"),
    Sidebar: () => import("@components/dashboard/Sidebar"),
    NotificationWrapper: () => import("@helper/NotificationWrapper"),
    Notify: () => import("@helper/Notify.vue"),
  },
  data() {
    return {
      navOpen: false,
    };
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/authenticated"];
    },
    isDataCheck() {
      return this.$store.getters.isDataCheck;
    },

    user() {
      return this.$store.getters["auth/user"];
    },

    upToDate() {
      return this.$store.getters['app/upToDate'];
    }
  },
  methods: {
    reload() {
      window.location.reload(true);
    },
  },
  watch: {
    $route() {
      this.navOpen = false;
    },
  },
  mounted() {
    this.$root.$notify = this.$refs.notify;
  },
};
</script>
